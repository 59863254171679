<template>
    <div>
        <el-form :inline="true" v-if="hasAuth('sys:reasoncancellation:add')">
            <el-form-item>
                <el-button type="primary" @click="saveButton(null)">新增</el-button>
            </el-form-item>
        </el-form>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe>

            <el-table-column prop="complaintCategory" align="center" label="投诉原因">
            </el-table-column>
            <el-table-column prop="reasonCancellationType" align="center" label="投诉原因类型" width='200'>
                <template slot-scope="{row}">
                    <span v-if="row.complaintTypeUse == 2">货主</span>
                    <span v-if="row.complaintTypeUse == 3">司机</span>
                </template>
            </el-table-column>
            <el-table-column prop="icon" align="center" width="300" label="操作">
                <template slot-scope="scope">
                    <el-button type="primary" plain @click="saveButton(scope.row)"
                        v-if="hasAuth('sys:reasoncancellation:update')">编辑</el-button>
                    <template v-if="hasAuth('sys:reasoncancellation:delete')">
                        <el-popconfirm title="确定要删除该条数据吗？" @confirm="delHandle(scope.row.complaintTypeId)">
                            <el-button type="danger" plain slot="reference">删除</el-button>
                        </el-popconfirm>
                    </template>
                </template>
            </el-table-column>
        </el-table>
        <typeedit ref="typeedit" v-if="typeeditvisible"
            @refreshDataList="getreasoncancellation"></typeedit>
    </div>
</template>

<script>
import typeedit from "./typeedit";
export default {
    name: "VehicletypeList",
    components: {
        typeedit
    },
    data() {
        return {
            tableData: [],
            typeeditvisible: false,
        }
    },
    created() {
        this.getreasoncancellation();
    },
    methods: {
        getreasoncancellation() {
            this.$axios.post("/admin/complaintType/getComplaintTypeList",{
                pageNo:1,
                pageSize:1000
            }).then(res => {
                this.tableData = res.data.records
            })
        },
        delHandle(id) {
            this.$axios.post("/admin/complaintType/deleteComplaintTypeInfo", { complaintTypeId: id }).then(res => {
                this.$message({
                    showClose: true,
                    message: '恭喜你，操作成功',
                    type: 'success',
                    onClose: () => {
                        this.getreasoncancellation()
                    }
                });
            })
        },
        saveButton(row) {
            this.typeeditvisible = true;
            this.$nextTick(() => {
                this.$refs.typeedit.init(row);
            })
        },
    }
}
</script>

<style scoped>
.el-button {
    padding: 0 10px;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    margin-right: 10px;
}
</style>

